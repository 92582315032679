<template>
	<aside class="horizontal-left-sidebar"
		:class="Sidebar_drawer ? `show-sidebar bg-${SidebarColor}` : `bg-${SidebarColor}`">
		<div class="container-fluid">
			<div :class="`bg-${logoColor} navbar-header d-block d-lg-none`">
				<LogoDark v-if="logoColor == 'white' || logoColor == 'purple'" />
				<LogoLight v-else />
				<span class="d-block d-lg-none close-sidebar-btn" @click="showMobileSidebar"><i
						class="ti ti-close"></i></span>
			</div>
			<div class="scrlbar">
				<ul class="nav mb-0">
					<template v-for="(route, i) in routes">
						<!---Sub Menu -->
						<li class="nav-item first-dd" :class="route.dd" :key="route.title" v-if="route.childern">
							<a class="nav-link d-flex">
								<!--<i  :class="route.icon"></i>-->
								<feather :type="route.icon"></feather>
								<span class="hide-text">{{ $t(route.i18n) || route.title }}</span>
								<i class="ti ti-angle-down dd-arrow hide-text"></i>
							</a>


							<ul class="nav flex-column sub-menu" :class="`bg-${SidebarColor}`">
								<li v-for="(subroute, i) in route.childern" :key="i" class="nav-item"
									@click="showMobileSidebar">
									<router-link class="nav-link d-flex" :to="`${subroute.to}`">
										<i :class="subroute.icon"></i>
										<span class="hide-text">{{ subroute.title }}</span>
									</router-link>
								</li>
							</ul>
						</li>
						<!---Single Menu -->
						<li class="nav-item" :key="i" v-if="!route.childern && !route.header"
							@click="showMobileSidebar">
							<router-link :to="`${route.to}`" class="nav-link d-flex">
								<!--<i  :class="route.icon"></i>-->
								<feather :type="route.icon"></feather>
								<span class="hide-text">{{ route.title }}</span>
							</router-link>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</aside>
</template>

<script>
import LogoDark from "../logo-dark/LogoDark";
import LogoLight from "../logo-light/LogoLight";
import { mapState, mapGetters } from "vuex";
import SidebarData from "./SidebarData";
export default {
	name: "HorizontalSidebar",
	components: {
		LogoDark,
		LogoLight,
	},
	data: () => ({
		initialShow: true,
		routes: SidebarData,
	}),
	computed: {
		...mapState("theme", ["logoColor", "Sidebar_drawer", "SidebarColor"]),
		...mapGetters({
			authorizations: "auth/getAuthorizations",
		}),


		routes() {
			return [
				{ header: "Total OCS" },
				// { header: "Total App", icon: "mdi mdi-dots-horizontal" },
				{
					id: "home",
					icon: "home",
					title: "Home",
					to: "/home",
					active: this.authorizations.includes("/Home"),
				},
				{
					id: "dashboard",
					icon: "grid",
					title: "Dashboard",
					to: "/dashboard",
					active: this.authorizations.includes("/Dashboards"),
				},
				{
					id: "customers",
					icon: "user",
					title: "Customers",
					active: ["/Profiles-GetAll", "/CustomerDocumentTypes-GetAll", "/CustomerTypes-GetAll", "/Profiles-GetAll-Active", "/Profiles-GetAll-Inactive"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "All Customers",
							to: "/customers",
							active: this.authorizations.includes("/Profiles-GetAll"),
						},
						{
							icon: "mdi mdi-account-multiple",
							title: "Active Customers",
							to: "/customers?status=active",
							active: this.authorizations.includes("/Profiles-GetAll-Active"),
						},
						{
							icon: "mdi mdi-account-multiple",
							title: "Inactive Customers",
							to: "/customers?status=inactive",
							active: this.authorizations.includes("/Profiles-GetAll-Inactive"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/general/customerReasonsTypes",
							active: this.authorizations.includes("/CustomerTypes-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Documents",
							to: "/customers/documentsTypes",
							active: this.authorizations.includes("/CustomerDocumentTypes-GetAll"),
						},
					],
				},
				{
					id: "loan-applications",
					icon: "dollar-sign",
					title: "Loan Applications",
					i18n: "Loan Applications",
					name: "/loans-applications/",
					active: ["/LoanApplications-GetAll", '/LoanApplications-Add', "/LoanApplications-Additional-Details",
						"/LoanApplications-Membercheck",
						"/LoanApplications-Screening",
						"/LoanApplications-Job-Verification",
						"/LoanApplications-Verify-Information",
						"/LoanApplications-Approval",
						"/LoanApplications-Signature",
						"/LoanApplications-Transfer-Of-Founds",
						"/LoanApplications-Details",
						"/LoanApplications-Completed",
						"/LoanApplications-Closed",
						"/LoanApplications-Assigned",
						"/LoanApplications-All"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "All",
							to: "/loans-applications/all",
							active: this.authorizations.includes("/LoanApplications-GetAll"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Assigned",
							to: "/loans-applications/assigned",
							active: this.authorizations.includes("/LoanApplications-Assigned"),
						},
						// {
						// 	icon: "mdi mdi-file-tree",
						// 	title: "New Loan Application",
						// 	to: "/loans-applications/new",
						// 	active: this.authorizations.includes("/LoanApplications-Add"),
						// },
						{
							icon: "mdi mdi-file-tree",
							title: "Additional Details",
							to: "/loans-applications/additional-details",
							active: this.authorizations.includes("/LoanApplications-Additional-Details"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Membercheck",
							to: "/loans-applications/membercheck",
							active: this.authorizations.includes("/LoanApplications-Membercheck"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Screening",
							to: "/loans-applications/screening",
							active: this.authorizations.includes("/LoanApplications-Screening"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Job Verification",
							to: "/loans-applications/job-verification",
							active: this.authorizations.includes("/LoanApplications-Job-Verification"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Verify All",
							to: "/loans-applications/verify-all",
							active: this.authorizations.includes("/LoanApplications-Verify-Information"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Approval",
							to: "/loans-applications/approval",
							active: this.authorizations.includes("/LoanApplications-Approval"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Signature",
							to: "/loans-applications/signature",
							active: this.authorizations.includes("/LoanApplications-Signature"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Transfer of Funds",
							to: "/loans-applications/transfer-funds",
							active: this.authorizations.includes("/LoanApplications-Transfer-Of-Founds"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Closed",
							to: "/loans-applications/closed",
							active: this.authorizations.includes("/LoanApplications-Closed"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Completed",
							to: "/loans-applications/completed",
							active: this.authorizations.includes("/LoanApplications-Completed"),
						},

					],
				},
				{
					id: "loans",
					icon: "dollar-sign",
					title: "Loans",
					i18n: "Loans",
					name: "/loans/",
					active: ["/LoanApplications-All", "/LoanApplications-Approved", "/LoanApplications-Active", "/LoanApplications-Pending-Approved", "/LoanApplications-Closed"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "All",
							to: "/loans/all",
							active: this.authorizations.includes("/LoanApplications-All"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Active",
							to: "/loans/active",
							active: this.authorizations.includes("/LoanApplications-Active"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Approved",
							to: "/loans/approved",
							active: this.authorizations.includes("/LoanApplications-Approved"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Pending Approval",
							to: "/loans/pending-approval",
							active: this.authorizations.includes("/LoanApplications-Pending-Approved"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Closed",
							to: "/loans/closed",
							active: this.authorizations.includes("/LoanApplications-Closed"),
						},

					],
				},
				{
					id: "products",
					icon: "user",
					title: "Products",
					name: "/products/",
					active: ["/LoanTypes", "/Rules"].some((string) =>
						this.authorizations.includes(string)
					),
					childern: [
						{
							icon: "mdi mdi-calculator",
							title: "Rules",
							to: "/products/rules",
							active: this.authorizations.includes("/Rules"),
						},
						{
							icon: "mdi mdi-file-tree",
							title: "Types",
							to: "/products/types",
							active: this.authorizations.includes("/LoanTypes"),
						},
					],
				},
				{
					id: "partners",
					icon: "user",
					title: "Partners",
					name: "/loans-affiliates/",
					active: ["/Partners-GetList"].some((string) =>
						this.authorizations.includes(string)
					),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "Index",
							to: "/loans-affiliates/list",
							active: this.authorizations.includes("/Partners-GetList"),
						},
						{
							icon: "mdi mdi-calculator",
							title: "Calculator",
							to: "/loans-affiliates/calculator",
							active: this.authorizations.includes("/Partners-GetList") && this.user.Roles[0] != "Admin",
						},
					],
				},
				{
					id: "banks-screening",
					icon: "briefcase",
					title: "Banks Screening",
					name: "/banks-screening/",
					active: ["/ScreeningVerification"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-file-tree",
							title: "List",
							to: "/banks-screening/list",
							active: this.authorizations.includes("/ScreeningVerification"),
						},
					],
				},
				{
					id: "general",
					icon: "settings",
					title: "General",
					i18n: "General",
					name: "/general/",
					active: ["/Banks-GetAll", "/Countries-GetAll", "/Cities-GetAll", "/States-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-bank",
							title: "Banks",
							to: "/general/banks",
							active: this.authorizations.includes("/Banks-GetAll"),
						},
						{
							icon: "mdi mdi-map",
							title: "Countries",
							to: "/general/countries",
							active: this.authorizations.includes("/Countries-GetAll"),
						},
						{
							icon: "mdi mdi-city",
							title: "Cities",
							to: "/general/cities",
							active: this.authorizations.includes("/Cities-GetAll"),
						},
						{
							icon: "mdi mdi-map-marker-multiple",
							title: "States",
							to: "/general/states",
							active: this.authorizations.includes("/States-GetAll"),
						},
					],
				},
				{
					id: "authentication",
					icon: "lock",
					title: "Authentication",
					i18n: "Authentication",
					name: "/authentication/",
					active: ["/Roles-GetAll", "/Users-GetAll"].some((string) => this.authorizations.includes(string)),
					childern: [
						{
							icon: "mdi mdi-account-multiple",
							title: "Users",
							to: "/security/users",
							active: this.authorizations.includes("/Users-GetAll"),
						},
						{
							icon: "mdi mdi-account-convert",
							title: "Roles",
							to: "/security/roles",
							active: this.authorizations.includes("/Roles-GetAll"),
						},
					],
				},
			];
		},
	},
	methods: {
		showMobileSidebar() {
			this.$store.commit("theme/SET_SIDEBAR_DRAWER", false);
		},
	},
};
</script>

<style></style>
