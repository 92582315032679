<template>
    <b-navbar-brand href="#" class="px-2">
        <b class="logo-icon mr-3">
            <img style="max-width: 30px;" src="../../../assets/images/LogoIcon.svg" alt="homepage">
        </b>

        <span class="logo-text">
            <img class="w-75" src="../../../assets/images/TOTAL-Logotype-Teal.png" alt="homepage">
        </span>
    </b-navbar-brand>
</template>

<script>
export default {
    name: 'LogoDark',
}
</script>
