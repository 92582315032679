import { jwtDecode } from "jwt-decode";

const token = localStorage.getItem("token");
const user = token ? jwtDecode(token) : null;
const session = user ? JSON.parse(user?.Session) : null;
const authorizations = session?.Authorizations || "ND";

export default [
  { header: "Total OCS" },
  // { header: "Total App", icon: "mdi mdi-dots-horizontal" },

  {
    id: "0",
    icon: "home",
    title: "Home",
    to: "/home"
  },
  {
    id: "1",
    icon: "user",
    title: "Customers",
    active: ["/Profiles-GetAll", "/CustomerDocumentTypes-GetAll", "/CustomerTypes-GetAll"].some(string => authorizations.includes(string)),
    childern: [
      {
        icon: "mdi mdi-account-multiple",
        title: "List",
        to: "/customers",
        active: authorizations.includes("/Profiles-GetAll")
      },
      {
        icon: "mdi mdi-file-tree",
        title: "Types",
        to: "/general/customerReasonsTypes",
        active: authorizations.includes("/CustomerTypes-GetAll")
      },
      {
        icon: "mdi mdi-file-tree",
        title: "Documents",
        to: "/customers/documentsTypes",
        active: authorizations.includes("/CustomerDocumentTypes-GetAll")
      }
    ]
  },
  {
    id: "2",
    icon: "dollar-sign",
    title: "Loans",
    i18n: "Loans",
    name: "/loans/",
    active: ["/LoanApplications-GetAll", "/Rules-GetAll", "/LoanTypes-GetAll"].some(string => authorizations.includes(string)),
    childern: [
      {
        icon: "mdi mdi-file-tree",
        title: "Applications",
        to: "/loans/applications",
        active: authorizations.includes("/LoanApplications-GetAll")
      },
      {
        icon: "mdi mdi-calculator",
        title: "Rules",
        to: "/loans/rules",
        active: authorizations.includes("/Rules-GetAll")
      },
      {
        icon: "mdi mdi-file-tree",
        title: "Types",
        to: "/loans/types",
        active: authorizations.includes("/LoanTypes-GetAll")
      }
    ]
  },
  {
    id: "5",
    icon: "dollar-sign",
    title: "Banks Screening",
    name: "/banks-screening/",
    active: ["/Banks-GetAll"].some(string => authorizations.includes(string)),
    childern: [
      {
        icon: "mdi mdi-file-tree",
        title: "List",
        to: "/banks-screening/list",
        active: authorizations.includes("/Banks-GetAll")
      }
    ]
  },
  {
    id: "3",
    icon: "settings",
    title: "General",
    i18n: "General",
    name: "/general/",
    active: ["/Banks-GetAll", "/Countries-GetAll", "/Cities-GetAll", "/States-GetAll"].some(string => authorizations.includes(string)),
    childern: [
      {
        icon: "mdi mdi-bank",
        title: "Banks",
        to: "/general/banks",
        active: authorizations.includes("/Banks-GetAll")
      },
      {
        icon: "mdi mdi-map",
        title: "Countries",
        to: "/general/countries",
        active: authorizations.includes("/Countries-GetAll")
      },
      {
        icon: "mdi mdi-city",
        title: "Cities",
        to: "/general/cities",
        active: authorizations.includes("/Cities-GetAll")
      },
      {
        icon: "mdi mdi-map-marker-multiple",
        title: "States",
        to: "/general/states",
        active: authorizations.includes("/States-GetAll")
      }
    ]
  },
  {
    id: "12",
    icon: "lock",
    title: "Authentication",
    i18n: "Authentication",
    name: "/authentication/",
    active: ["/Roles-GetAll", "/Users-GetAll"].some(string => authorizations.includes(string)),
    childern: [
      {
        icon: "mdi mdi-account-multiple",
        title: "Users",
        to: "/security/users",
        active: authorizations.includes("/Users-GetAll")
      },
      {
        icon: "mdi mdi-account-convert",
        title: "Roles",
        to: "/security/roles",
        active: authorizations.includes("/Roles-GetAll")
      }
    ]
  }
];
