<template>
  <footer class="p-4 text-center">
    © {{ new Date().getFullYear() }} — Handcrafted by <a href="https://qwihi.com/" target="_blank">Qwihi Innovations</a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
</style>